import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';
import { renderAlert, shortenResponse } from '../../common/reportHelpers.js';
import responsesQuery from './responses.graphql';
import thermo from '../../images/LIFT/thermo.png';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const MAX_CHAR_LENGTH = 115;

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [reportSettings, setReportSettings] = useState({
        10: '',
        6: '',
        0: '',
    });

    let chapterId = props.settings?.chapter || '2';
    const chapterTitle =
        props.settings.chapter === '4' ? 'My' + props.settings.chapterTitle : props.settings.chapterTitle;

    useEffect(() => {
        getData();
    }, [chapterId]);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress['chapter' + chapterId];

        if (Object.values(data).length > 0) {
            setHasModuleProgress(true);
        } else {
            setHasModuleProgress(false);
        }

        if (chapterId === '4') {
            setReportSettings({
                10: shortenResponse(data.Chapter4Stress10, MAX_CHAR_LENGTH),
                8: shortenResponse(data.Chapter4Stress8, MAX_CHAR_LENGTH),
                6: shortenResponse(data.Chapter4Stress6, MAX_CHAR_LENGTH),
                4: shortenResponse(data.Chapter4Stress4, MAX_CHAR_LENGTH),
                2: shortenResponse(data.Chapter4Stress2, MAX_CHAR_LENGTH),
                0: shortenResponse(data.Chapter4Stress0, MAX_CHAR_LENGTH),
            });
        } else {
            setReportSettings({
                10: shortenResponse(data.Chapter2Stress10, MAX_CHAR_LENGTH),
                6: shortenResponse(data.Chapter2Stress6, MAX_CHAR_LENGTH),
                0: shortenResponse(data.Chapter2Stress0, MAX_CHAR_LENGTH),
            });
        }

        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    if (!props.settings.chapter) {
        return renderAlert('Please select a Report Chapter in Report Settings');
    }

    return (
        <Report downloadFilename={`LIFT_Chapter_${chapterId}_${chapterTitle}_Report`}>
            <Page>
                <div className={style.flexWrapper}>
                    <ReportHeader chapterId={chapterId} chapterTitle={chapterTitle} />
                    {hasModuleProgress ? (
                        <>
                            {chapterId === '4' && (
                                <div className={style.chapter4text}>
                                    <strong>
                                        Note: Your responses will be erased when you close your browser. If you&apos;d
                                        like a copy of your stress thermometer, you will need to print and/or save it
                                        now.
                                    </strong>
                                    <div style={{ marginTop: '1em' }}>
                                        Choose an activity you rated as a 4 or a 2 on your stress thermometer and
                                        practice the activity until you can work your way up to the next. Make sure you
                                        can do the activity safely and tell a parent or other trusted adult beforehand.
                                    </div>
                                </div>
                            )}
                            <div className={style.body}>
                                <img src={thermo} alt="thermometer" className={style.thermo} />
                                <div className={style.responses}>
                                    <div>
                                        <strong className={style.rating}>10</strong>
                                        {reportSettings['10']}
                                    </div>
                                    {reportSettings['8'] && (
                                        <div>
                                            <strong className={style.rating}>8</strong>
                                            {reportSettings['8']}
                                        </div>
                                    )}
                                    <div>
                                        <strong className={style.rating}>6</strong>
                                        {reportSettings['6']}
                                    </div>
                                    {reportSettings['4'] && (
                                        <div>
                                            <strong className={style.rating}>4</strong>
                                            {reportSettings['4']}
                                        </div>
                                    )}
                                    {reportSettings['2'] && (
                                        <div>
                                            <strong className={style.rating}>2</strong>
                                            {reportSettings['2']}
                                        </div>
                                    )}
                                    <div>
                                        <strong className={style.rating}>0</strong>
                                        {reportSettings['0']}
                                    </div>
                                </div>
                            </div>
                            <ReportFooter />
                        </>
                    ) : (
                        renderAlert('No responses were recorded yet.')
                    )}
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
